#inner-work1{
  .hamburger-icon{
        
    svg{
        cursor: pointer;
        stroke: white;
        fill: white;
        stroke-width: 1px;
    }
}
.main-logo{
  svg {
      path{
          fill: #fff;
      }
  }
}
}
#hero-work {
    width: 100%;
	height: 100vh;
	overflow: hidden;
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
}

.layer {
	background-position: bottom center;
	background-size: auto;
	background-repeat: no-repeat;
	width: 100%;
	height: 100vh;
	position: absolute;
  top: 0;
	z-index: -1;
}

#hero-mobile {
	display: none;
	background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/272781/full_illustration.png") no-repeat center bottom / cover;
	height: 320px;
}

#hero-work, .layer {
	min-height: 800px;
  left: 0px!important;
}

.layer-3 {
	// background-image: url('https://yodezeen.com/wp-content/uploads/2018/07/KO5A0768.jpg');
	background-position: center center;
  left: 0px!important;
  background-size: cover;
}

.layer-3::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.hero-wrapper{
    width: 100%;
    margin: auto;
    padding-left: 350px;
    padding-top: 300px;
}

.hero-wrapper h1{
    font-size: 70px;
    line-height: 1.2;
    font-weight: 600;
    position: relative;
    color: white;
}

.hero-wrapper .anime-line{
    position: absolute;
    width: 1px;
    height: 88px;
    background: #e0e0e0;
    left: 50%;
    bottom: 1%;
    animation: animate-line 2s linear infinite;
}

@keyframes animate-line{
    0% {
        height: 0px;
    }

    50%{
      height: 88px;
    }

    100% {
        height: 0px;
    }
}

.inner-work_gallery-wrapper{
    padding-top: 200px;
    background-color: #F7F7F7;
}

.blueprints {
    position: relative;
    width: 1000px;
    height: 750px;
    margin-left: auto;
    margin-right: auto;
    // border: 1px solid black;
  }

  .blueprints-main{
      background: #f7f7f7;
  }

  .slider-button {
    pointer-events: none;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    left: calc(50% - 18px);
    top: calc(50% - 18px);
    border: 1px solid black;

  }


  .img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 900px 100%;
  }

  // .background-img {
  //   // background-image: url('../../images/13\ furniture-1.png');
  // }

  .foreground-img {
    // background-image: url('../../images/13\ wall-1.png');
    width: 50%;
  }    



#comparison {
    width: 100%;
    height: 100%;
    margin-bottom: 200px;
    overflow: hidden; 
    position: relative;

    figure{
      background-size: 100% 100%;
    }
}

figure { 
  position: absolute;
	// background-image: url('../../images/13\ furniture-1.png');
	background-size: 100% 100%;
	font-size: 0;
	width: 100%;
	height: 100%;
	margin: 0; 
}

#divisor { 
	// background-image: url('../../images/13\ wall-1.png');
	background-size: cover;
  background-repeat: no-repeat;
	position: absolute;
	width: 50%; 
	box-shadow: 0 5px 10px -2px rgba(0,0,0,0.0);
	bottom: 0; height: 100%;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    right: -2px;
    width: 4px;
    height: calc(50% - 25px);
    background: white;
    z-index: 3;
  }
  &::before {
    top: 0;
    box-shadow: 0 -3px 8px 1px rgba(255,102,0,.1);
    background-color: rgba(255,102,0,0.5);
  }
  &::after {
    bottom: 0;
    box-shadow: 0 3px 8px 1px rgba(255,102,0,.1);
    background-color: rgba(255,102,0,0.5);
  }
}
#handle {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
  background-color: #000;
  
  &::before,
  &::after {
    content: '';
    width: 0;
    height: 0;
    border: 6px inset transparent;
    position: absolute;
    top: 50%;
    margin-top: -6px;
  }
  &::before {
    border-right: 6px solid white;
    left: 50%;
    margin-left: -17px;
  }
  &::after {
    border-left: 6px solid white;
    right: 50%;
    margin-right: -17px;
  }
}

input[type=range]{
	-webkit-appearance:none;
	-moz-appearance:none;
	position: absolute;
	top: 50%; left: -25px;
    transform: translateY(-50%);
    background-color: transparent;
    width: calc(100% + 50px); 
    z-index: 2;
    opacity: 0;
    height: 100%;
  
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}


input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: transparent;
  border: 4px solid white;
  box-shadow: 0 0 8px 1px rgba(0,0,0,.3);
}


input[type=range]::-moz-range-track {
	-moz-appearance:none;
	height:15px;
	width: 100%;
	background-color: transparent; 
	position: relative;
	outline: none;    
}



#inner-work1{
      background: transparent
  }


.details__card {
    display: flex;
    margin-bottom: 25px;
}

.card__icon {
    margin: 0 40px;
}

.card__title{
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px!important;
}

.details .card__text {
    font-weight: 300;
    color: #666;
    letter-spacing: 0.7px;
    font-size: 14px;
}

.about__details{
    padding-left: 100px;
}

/* Main Briefing */

.main-briefing{
    position: relative;
    width: 100%;
    background: #f7f7f7;
    

}

.padding-briefing{
    padding-top: 150px;
    padding-bottom: 130px;
}
.briefing-inner{
    width: 100%;
    height: 700px;
    overflow: hidden;
}

.briefing-inner img{
    width: 100%;
    height: 120%;
}

.brief-title {
    padding-left: 15px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
     span{
         opacity: 1;
     }
}

.brief-content {
    color: #666;
    line-height: 1.8;
}

.team{
    position: relative;
    width: 100%;
    height: 100vh;
    background: #1a1a1a;
}

.team .about__bg {
    position: absolute;
    /* width: 100%; */
    top: 20%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 0;
    line-height: 1;
    font-size: 12.5vw;
    font-weight: 600;
    text-align: center;
    letter-spacing: 9.4vw;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.09;
    display: flex;
    text-shadow: 9px 17px 25px rgba(0,0,0,0.12);
}

.inner-work__brief{
    width: 100%;
    background: #f7f7f7;
}

.main-briefing{
    position: relative;
    width: 100%;
    background: #f7f7f7;
    

}

.padding-briefing{
    padding-top: 150px;
    padding-bottom: 130px;
}
.briefing-inner{
    width: 100%;
    height: 700px;
    overflow: hidden;
}

.briefing-inner img{
    width: 100%;
    height: 120%;
}

.brief-title {
    padding-left: 15px;
    color: #000;
    font-weight: 600;
    line-height: 1.2;
}

.brief-content {
    color: #666;
    line-height: 1.8;
}

@media only screen and (max-width: 480px) {
  .hero-wrapper{
    padding-left: 20px;

    h1{
      font-size: 36px;
      line-height: 46px;
    }
  }

  .brief-title{
    padding-left: 5px;
  }

  .brief-content{
    padding: 15px 5px;
  }

  .padding-briefing{
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .blueprints{
    width: 100%;
    height: 300px;
  }

  #comparison {
    width: 100%;
    height: 100%;
    margin-bottom: 60px;
    overflow: hidden;
    position: relative;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  background-color: transparent;
  width: 100%;
  z-index: 2;
  opacity: 0;
  height: 100%;
}
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .hero-wrapper{
    padding-left: 20px;

    h1{
      font-size: 42px;
      line-height: 57px;
    }
  }

  .brief-title{
    padding-left: 5px;
  }

  .brief-content{
    padding: 15px 5px;
  }

  .padding-briefing{
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .brief-title{
    padding-left: 5px;
  }

  .brief-content{
    padding: 15px 5px;
  }

  .padding-briefing{
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .blueprints{
    width: 100%;
    height: 450px;
  }

  #comparison {
    width: 100%;
    height: 100%;
    margin-bottom: 60px;
    overflow: hidden;
    position: relative;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  background-color: transparent;
  width: 100%;
  z-index: 2;
  opacity: 0;
  height: 100%;
}
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
  .hero-wrapper{
    padding-left: 20px!important;

    h1{
      font-size: 42px;
      line-height: 57px;
    }
  }

  .padding-briefing{
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .blueprints{
    width: 100%;
    height: 550px;
  }

  #comparison {
    width: 100%;
    height: 100%;
    margin-bottom: 60px;
    overflow: hidden;
    position: relative;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  background-color: transparent;
  width: 100%;
  z-index: 2;
  opacity: 0;
  height: 100%;
}
}