#projects{
    background: #1A1A1A;
    .hamburger-icon{
        
        svg{
            cursor: pointer;
            stroke: white;
            fill: white;
            stroke-width: 1px;
        }
    }

    .main-logo{
        svg {
            path{
                fill: #fff;
            }
        }
    }
    .small-wrapper--custom{
        padding-left: 350px;
        max-width: 1300px;
    }

    .projects-tabs{
        position: relative;
        width: 100%;
        padding-top: 236px;
        padding-bottom: 100px;

        min-height: 100vh;
        overflow: hidden;

        .bg-text{
            position: absolute;
            /* width: 100%; */
            top: 25%;
            transform: translate(-50%, -50%);
            left: 55%;
            z-index: 0;
            line-height: 1;
            font-size: 12.5vw;
            font-weight: 600;
            text-align: center;
            letter-spacing: 9.4vw;
            text-transform: uppercase;
            color: #000000;
            opacity: 1;
            display: flex;
            text-shadow: 9px 17px 25px rgba(0,0,0,0.12);
        }
        
        .projects-tab__inner{
            max-width: 100%;
            padding-left: 00px;
            // margin: auto;
            margin-bottom: 30px;
            display: flex;
            position: relative;

            .tabs-button{
                margin-right: 50px;
                background: transparent;
                border-bottom: 1px solid white;
                border-top: 0px;
                border-left: 0px;
                border-right: 0px;
                color: white;
                padding: 5px 0px 5px 0px;
                z-index: 5;
            }
        
        }

        .hover-reveal {
            position: absolute;
            z-index: -1;
            width: 220px;
            height: 320px;
            top: 0;
            left: 0;
            pointer-events: none;
            opacity: 0;
        }
        
        .hover-reveal__inner {
            overflow: hidden;
        }
        
        .hover-reveal__inner,
        .hover-reveal__img {
            width: 100%;
            height: 100%;
            position: relative;
        }
        
        .hover-reveal__img {
            background-size: cover;
            background-position: 50% 50%;
        }

        .hover-list{
            display: flex;
            flex-direction: column;
            padding-top: 60px;
        
        }
    }

    .menu__item {
        flex: none;
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding-left: 5rem;
        text-decoration: none;
        color: white;

        .menu__item-img{
            position: absolute;
            top: -35%;
            left:  55%;
            z-index: 1;
            opacity: 0;
            width: 50%;
            pointer-events: none;
            transition: all 0.5s;
            
        }

        &:hover > .menu__item-img {
            opacity: 1;
            transition: all 0.5s;
        }

        &:hover > .menu__item-text {
            z-index: 155;
        }

        &:hover {
            color: #f60;
        }
    }
    
    // .menu__item::before {
    //     counter-increment: menucounter;
    //     content: counters(menucounter, ".", decimal-leading-zero);
    //     position: absolute;
    //     left: 0;
    //     border-left: 3px solid currentColor;
    //     top: 20%;
    //     height: 60%;
    //     padding-left: 1rem;
    //     display: flex;
    //     align-items: center;
    //     line-height: 1;
    //     font-weight: bold;
    //     opacity: 0;
    //     transform: translateX(-1rem);
    //     transition: transform 0.3s, opacity 0.3s;
    // }
    
    // .menu__item:hover::before {
    //     opacity: 1;
    //     transform: translateX(0);
    // }
    
    .menu__item-text {
        position: relative;
        cursor: pointer;
        padding: 0.5rem;
        display: block;
        overflow: hidden;
        font-size: 6vw;
        
    }
    
    .menu__item-textinner {
        display: block;
        font-weight: 300;
        text-transform: uppercase;
        white-space: nowrap;
        color: var(--color-menu);
    }
    
    .js .menu__item-textinner {
        transform: translateY(100%);
    }
    
    .menu__item:hover .menu__item-textinner {
        color: var(--color-menu-hover);
    }
    
    .menu__item-sub {
        display: none;
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        align-items: center;
        position: relative;
        margin-left: 2rem;
        padding-left: 3rem;
        opacity: 0;
        transform: translateX(-1rem);
        transition: transform 0.3s, opacity 0.3s;
    }
    
    .menu__item:hover .menu__item-sub {
        opacity: 1;
        transform: translateX(0);
    }
    
    .menu__item-sub::before {
        content: '';
        position: absolute;
        left: 0;
        top: 15%;
        width: 1px;
        height: 70%;
        background: currentColor;
        transform-origin: 0 100%;
        transform: rotate(22.5deg) scale3d(1,0,1);
        transition: transform 0.3s;
    }
    
    .menu__item:hover .menu__item-sub::before {
        transform: rotate(22.5deg) scale3d(1,1,1);
    }
    
    .hover-reveal {
        position: absolute;
        z-index: -1;
        width: 220px;
        height: 320px;
        top: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;
    }
    
    .hover-reveal__inner {
        overflow: hidden;
    }
    
    .hover-reveal__inner,
    .hover-reveal__img {
        width: 100%;
        height: 100%;
        position: relative;
    }
    
    .hover-reveal__img {
        background-size: cover;
        background-position: 50% 50%;
    }
    
    .cursor {
        display: none;
    }
    
    .credits {
        align-self: flex-start;
        padding: 5rem 0 0 0;
    }
    
    .credits a {
        text-decoration: underline;
    }
    
    @media screen and (min-width: 53em) {
        .frame {
            flex-direction: column;
            align-items: flex-end;
            text-align: right;
            background: none;
            height: 50vh;
            pointer-events: none;
            padding-bottom: 0;
        }
        .frame__links a:not(:last-child) {
            margin-right: 0;
        }
        .frame__links {
            margin: 0.5rem 0 2rem;
            justify-self: start;
        }
        .frame__links a {
            margin-left: 0.5rem;
            pointer-events: auto;
        }
        .frame__button {
            cursor: not-allowed;
            pointer-events: auto;
            margin-top: auto;
            transform: translateY(50%);
        }
        .menu {
            padding-top: 10vh;
        }
        .menu__item {
            padding-left: 0;
            justify-content: initial;
        }
        .menu__item-text {
            padding: 1vh 0;
            font-size: 4vw;
        }
        .menu__item-sub {
            display: flex;
        }
    }
    

    #projects .projects-tabs{
        padding-top: 80px!important;
    }
    
 
    
}

@media only screen and (max-width: 768px){
    #projects .small-wrapper--custom{
        padding-left: 0px;
    }

    #projects .projects-tabs{
        padding-top: 80px!important;
    }

    #projects .projects-tabs .projects-tab__inner{
        padding-left: 20px;
    }

    #projects .projects-tabs .projects-tab__inner .tabs-button{
        margin-right: 20px;
    }

    #projects .menu__item-text{
        padding: 10px 20px
    }

    #projects .projects-tabs .projects-tab__inner{
        margin-left: auto;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    #projects .small-wrapper--custom{
        padding-left: 20px;
    }

    #projects .menu__item-text{
        padding: 10px 20px
    }

}

@media only screen and (min-width: 769px) and (max-width: 846px){
   
    #projects .projects-tabs .projects-tab__inner{
        margin-left: auto;
    }
}