.items {
    height:600px;
    padding: 100px;
    width:100%!important;
    /*border:1px solid white;*/
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    position: relative;
    font-size: 0;
    perspective: 500px;
    transition: all .5s ease;
  }

  .items:nth-child(2){
      width: 200px;
  }
  
  .items.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(0.6);
  }

  .item.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(0.8);
    transition: all .4s ease;
  }

  .item {
    width: 500px;
    height: calc(100%);
    margin-left: 30px;
    margin-right: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    font-weight: 100;
    transition: all .4s ease;
  }

  
  .item:nth-child(9n+1) { background-image: url('../images/homeImage1.webp') ; background-size: cover;background-repeat: no-repeat;}
  .item:nth-child(9n+2) { background-image: url('../images/homeImage2.webp') ; background-size: cover;background-repeat: no-repeat;}
  .item:nth-child(9n+3) { background-image: url('../images/homeImage3.webp') ; background-size: cover;background-repeat: no-repeat;}
  .item:nth-child(9n+4) { background-image: url('../images/homeImage4.webp') ; background-size: cover;background-repeat: no-repeat;}
  .item:nth-child(9n+5) { background-image: url('../images/homeImage5.webp') ; background-size: cover;background-repeat: no-repeat;}
  .item:nth-child(9n+6) { background-image: url('../images/homeImage6.webp') ; background-size: cover;background-repeat: no-repeat;}
  .item:nth-child(9n+7) { background-image: url('../images/homeImage3.webp') ; background-size: cover;background-repeat: no-repeat;}
  .item:nth-child(9n+8) { background-image: url('../images/homeImage4.webp') ; background-size: cover;background-repeat: no-repeat;}
  .item:nth-child(9n+9) { background-image: url('../images/homeImage2.webp') ; background-size: cover;background-repeat: no-repeat;}

  #draggable{
      width: 100%;
      overflow: hidden;
      padding-top: 230px;
      padding-bottom: 230px;
      position: relative;

      .bg-text{
        position: absolute;
        /* width: 100%; */
        top: 30%;
        transform: translate(-50%, -50%);
        left: 50%;
        z-index: 0;
        line-height: 1;
        font-size: 12.5vw;
        font-weight: 600;
        text-align: center;
        // letter-spacing: 9.4vw;
        text-transform: uppercase;
        color: #000000;
        opacity: 0.02;
        display: flex;
        text-shadow: 9px 17px 25px rgba(0,0,0,0.12);
        pointer-events: none;

        span{
            margin-left: 4.2vw;
            margin-right: 4.2vw;
        }
    }

    .scroll_top{
        top: 72px;
    }

    .scroll_bottom {
        bottom: 72px;
    }

    .scroll {
        position: absolute;
        left: 50%;
        width: 1px;
        height: 88px;
        z-index: 1;
        // overflow: hidden;
    }

    .scroll::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #e0e0e0;
        top: 0%;
        left: 0;
    }

    .inner-card{
      margin-bottom: 40px;
    }

    .inner-card__title{
      margin: 0px!important;
      font-size: 14px;
      font-weight: 700;
    }
    .inner-card__content{
      color: #666;
      line-height: 26px;
      font-weight: 300;
    }
  }

  .about{
      max-width: 900px;
      margin: auto;

      .row > *{
        padding-left: 0px;
        padding-right: 0px;
      }
  }

  .about-head{
      font-size: 36px;
      font-weight: 600;
      line-height: 42px;
      margin-bottom: 25px;
  }

  .about-content{
    color: #666;
    font-weight: 300;
    margin-bottom: 50px;
  }
  

  // RESPONSIVE

@media only screen and (max-width: 600px){
  #draggable {
    width: 100%;
    overflow: hidden;
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
}

#draggable .scroll{
  display: none;
}

.about-head{
  font-size: 28px;
  line-height: 33px;
  padding-left: 10px;
  padding-right: 10px;
}

.about-content{
  padding-left: 10px;
  padding-right: 10px;
}

.inner-card{
  padding-left: 10px;
  padding-right: 10px;
}

#draggable .slider{
  display: none;
}
}

@media only screen and (min-width: 600px) and (max-width: 767px){
  #draggable .slider{
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
  #draggable .slider{
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #draggable .slider{
    display: none;
  }
}


@media only screen and (min-width: 1200px) and (max-width: 1360px){

}