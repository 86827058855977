
#contact{

    .col-md-12,.col-md-4{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    input{
        border-radius: 0px!important;
    }
    textarea{
        border-radius: 0px;
    }
    .hamburger-icon{
        
        svg{
            cursor: pointer;
            stroke: #111;
            fill: #111;
            stroke-width: 1px;
        }
    }
}
.contacts{
    padding-top: 300px;

    .line span{
        font-size: 70px;
        line-height: 1.2;
        font-weight: 600;
        color: #111;
    }

    .bg-text{
        position: absolute;
        /* width: 100%; */
        top: 30%;
        transform: translate(-50%, -50%);
        left: 55%;
        z-index: 0;
        line-height: 1;
        font-size: 12.5vw;
        font-weight: 600;
        text-align: center;
        letter-spacing: 9.4vw;
        text-transform: uppercase;
        color: #000000;
        opacity: 0.02;
        display: flex;
        text-shadow: 9px 17px 25px rgba(0,0,0,0.12);
    }
}

#contact{
    .form-custom{
        padding-top: 150px;
        padding-bottom: 180px;
    }

    .radio-btns{
        padding-bottom: 100px;
    }

    .card {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: transparent;
        background-clip: border-box;
        border-radius: 6px;
        -moz-box-shadow: 0px 0px 5px 0px rgba(212, 182, 212, 1);
        margin-bottom: 0px!important;
    }
    
    .form-control {
        border-color: transparent transparent rgba(0, 0, 0, 0.199) transparent;
        background: transparent;
        outline: 0 !important
    }
    
    .form-control:focus {
        border-color: transparent transparent #111 transparent;
        background: transparent;
        outline: 0 !important;
        box-shadow: 0 0 0 0rem rgba(0, 123, 255, .25)
    }
    
    .form-control {
        position: relative;
        display: inline-block;
        overflow: hidden
    }
    
    .form-control>input[type=text] {
        border: none;
        border-bottom: 3px solid red;
        outline: none
    }

    textarea::placeholder{
        color: #6c757d!important
    }

    textarea{
        color: #6c757d!important
    }
    
    .underline-animation {
        transition: all 1s;
        display: inline-block;
        bottom: 0;
        left: -100%;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #64e4fe
    }
    
    .form-control>input[type=text]:focus+.underline-animation {
        left: 0
    }
    
    .send-button {
        float: right
    }
    
    .send-button .button {
        font: inherit;
        line-height: normal;
        cursor: pointer;
        background-color: #00d2ff;
        color: white;
        padding: 11px;
        border: none;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.14286em;
        transition-duration: 0.4s
    }
    
    .send-button .button:hover,
    .form .button:focus,
    .form .button:active {
        box-shadow: 0 10px 15px rgba(0, 0, 0, .1)
    }
    
    .send-button .button:active {
        position: relative;
        top: 1px;
        left: 1px
    }

    .radio {
        background: transparent;
        padding: 4px;
        border-radius: 0px;
        position: relative;
        p{
            color: #6c757d;
            margin-bottom: 25px!important;
        }
    }
    
    .radio input {
        width: auto;
        height: 100%;
        appearance: none;
        outline: none;
        cursor: pointer;
        border-radius: 0px;
        border: 1px solid #111;
        margin-right: 50px;
        padding: 4px 15px;
        color: #111;
        font-size: 14px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        transition: all 100ms linear;
    }
    
    .radio input:checked {
       
        color: #fff;
        background: #111;
       
    }
    
    .radio input:before {
        content: attr(label);
        display: inline-block;
        text-align: center;
        width: 100%;
    }
}

@media only screen and (max-width: 876px){

    #contact .col-12{
        padding-left: 20px;
        padding-right: 20px;
    }
    .small-wrapper{
        h1{
            padding-left: 20px!important;
            font-size: 28px;
        }
    }

    .contacts .line span{
        font-size: 28px!important;
        line-height: 42px!important;
    }

    .contacts{
        padding-top: 150px!important;
    }

    #contact .form-custom{
        padding-top: 50px!important;
    }

    .contacts .bg-text{
        display: none!important;
    }

    #contact .radio-btns{
        display: none;
    }
}