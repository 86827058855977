.footer-head{
    margin: auto;
    padding-bottom: 40px;
}
.footer-caption{
    
    width: 50%;
    padding-left: 10px;
    color: #666;
    margin-bottom: 100px;
    
}


.footer-head h1{
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    color: #fff;
}

.footer-title{
    font-size: 11px!important;
    letter-spacing: 1.4px!important;
    font-weight: 600!important;
    text-transform: uppercase;
    color: #666!important;
    p{
        margin: 0!important;
    }
}

.footer-inner{
    .dark-link__parent{
        margin-left: 10px;
    }
}

.footer-content{
    margin: 0px!important;
    // margin-top: 10px!important;
    font-size: 14px!important;
    color: #fff!important;
    font-weight: 300!important;
    line-height: 1.8;
}

.last-col{
    margin-left: auto!important;
}

.copyright{
    padding-top: 20px;
    padding-bottom: 0px;
    font-size: 11px;
    line-height: 2;
    font-weight: 700;
    color: #666;
    opacity: 0.5;
    margin: auto;
    text-align: center;

    p{
        margin-bottom: 0px!important;
    }
}

#footer{
    width: 100%;
    background: #1a1a1a;
    position: relative;
    padding-top: 230px;
    overflow: hidden;
    margin: auto;

    .footer-main{
        max-width: 900px;
        margin: auto;
    }

    .scroll_top{
        top: 72px;
    }

    .scroll_bottom {
        bottom: 72px;
    }

    .scroll {
        position: absolute;
        left: 50%;
        width: 1px;
        height: 88px;
        z-index: 1;
        // overflow: hidden;
    }

    .scroll::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #e0e0e0;
        top: 0%;
        left: 0;
        opacity: 0.3;
    }

    .mysvg{
        position: absolute;
        top: -20%;
        left: 55%;
        width: 120%;

        #Copyright{
            display: none;
            
        }
    }

}

@media only screen and (max-width: 600px){
    #footer{
        padding-top: 60px;
    }
    #footer .footer-main{
        padding: 0 10px;
    }
    #footer .scroll{
        display: none
    }

    .footer-head{
        padding-bottom: 60px;

        h1{
            font-size: 28px;
        }
    }

    // .dark-link__parent{
    //     margin-left: 0px!important;
    // }

    .footer-caption{
        padding-left: 0px;
        width: 80%;
    }

    
}
