.header__wrapper{
    width: 100%;  
    position: absolute;
    overflow: hidden;
    top: 0%;
    left: 0%;
    z-index: 10;
}

.mobile-list{
    display: block;
    .main-list{
        position: fixed;
        top: 0;
        left: 0;
        left: -100%;
        background-color: #111;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        z-index: 10;
        justify-content: center;
        display: flex;
        span{
            margin-top: 20px!important;
            text-align: center!important;
            color: white;

            a{
                color: #fff!important;
                text-decoration: none;
                font-size: 24px;
            }
        }
    }
}

.desktop{
    display: flex;

    .main-list{
        display: flex;
        align-items: center;
    }
    
    .header__dark .main-list span{
        margin: 0px 30px;
        color: #333;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
    }
    
    .header__dark .main-list span:hover{
        color: #666;
    }
    
}

.header__inner{
    a{
        // color: #fff;
        font-weight: 600;
        font-size: 14px;
        color: #111;
        text-decoration: none;
    }
    width: 100%;
    padding: 40px 80px;
    display: flex;
    justify-content: space-between;
}

.header__dark{
    background: transparent;
}

.header__light .main-list span{
    margin: 0px 30px;
    // color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.main-logo{
    width: 100px;
    svg{
        width: 100%;
        height: 100%;
    }
}

.main-logo img{
    width: 100%;
}


.close-icon{
    display: none;
    svg{
        cursor: pointer;
        stroke: white;
        fill: white;
        stroke-width: 1px;
    }
}

.hamburger-icon{
    display: none;
    svg{
        cursor: pointer;
        // stroke: white;
        // fill: white;
        stroke-width: 1px;
    }
}

@media only screen and (max-width: 480px){

    .desktop{
        display: none;
    }

    .header__inner{
        margin-top: 20px;
        padding: 0px;

        a{
            font-size: 24px!important;
            line-height: 30px!important;
            text-align: center;
        }

        .main-logo{
            width: 80px;
        }
    }
    .hamburger-icon{
        display: flex;
        align-items: center;
    }

    .close-icon{
        display: block;
        position: absolute;
        top: 30px;
        right: 10px;
    }

    .header__wrapper{
        overflow: auto;
        // height: 100%;
    }

    .mobile-list{
        display: block;
    }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
    
    .header__inner{
        margin-top: 20px;
        padding: 0px;

        a{
            font-size: 24px!important;
            line-height: 30px!important;
            text-align: center;
        }

        .main-logo{
            width: 80px;
        }
    }
    .hamburger-icon{
        display: flex;
        align-items: center;
    }

    .close-icon{
        display: block;
        position: absolute;
        top: 30px;
        right: 10px;
    }

    .header__wrapper{
        overflow: auto;
        // height: 100%;
    }

    .main-list{
        position: absolute;
        top: 0;
        left: -100%;
        background-color: #111;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        z-index: 5;
        justify-content: center;

        span{
            margin-top: 20px!important;
            text-align: center!important;
            
        }
    }
    
}

@media only screen and (min-width: 769px) and (max-width: 1024px){
    .header__inner{
        padding: 40px 10px;
    }
}