#about{
    background: #f7f7f7;
    width: 100%;
    height: auto;
    padding-top: 100px;

    .hamburger-icon{
        
        svg{
            cursor: pointer;
            stroke: #111;
            fill: #111;
            stroke-width: 1px;
        }
    }

    .main-btn{
        display: none;
    }

    #smallWrapper .scroll_bottom{
        bottom: 0px;
    }
    
    #smallWrapper{
        margin-bottom: 100px;
    }

    .carousel .carousel-status{
        display: none;
    }

    .carousel-inner{
        // &::after{
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: rgba(0,0,0,0.6);
        // }

        img{
            filter: grayscale(1);
            // filter: blur(2);
        }
    }
}

#about-head{
    #header{
        .desktop{
        .main-list{
            .menu__link a{
                color: #111!important;
            }
        }
    }
    }
}

.about-carousel{
    overflow: hidden;
    width: 100%;

    background: #f7f7f7
}

.carousel.carousel-slider{
    max-height: 100vh;
}

.about-carousel__inner{
    padding: 0px 40px;
    width: 100%;
    
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    user-select: none;

    img{
        user-select: none;
    }
}

.carousel .thumbs{
    padding: 0px;
}

.carousel .thumbs-wrapper{
    margin: 20px 0px;
}

.about-carousel__inner img{
    width: 100%;
    pointer-events: none;
}

.nav-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.arrow-btn{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    width: 150px;
}

.prev-arrow__parent{
    position: relative;
    width: 100px;
}

.arrow-btn .prev-arrow{
    width: 100%;
    height: 2px;
    background: #bdbdbd;
    position: absolute;
    display: flex;
    top: 50%;
    align-items: center;
}

.prev, .next{
    font-weight: 700;
    color: #bdbdbd;
}

.strengths{
    background: #f7f7f7;
    padding: 192px 0 0px;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.strengths .about__bg {
    position: absolute;
    /* width: 100%; */
    top: 20%;
    transform: translate(-50%, -50%);
    left: 55%;
    z-index: 0;
    line-height: 1;
    font-size: 12.5vw;
    font-weight: 600;
    text-align: center;
    letter-spacing: 9.4vw;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.02;
    display: flex;
    text-shadow: 9px 17px 25px rgba(0,0,0,0.12);
}

.small-wrapper{
    max-width: 900px;
    margin: auto;
}

.strengths-head{
    padding-left: 50%;

}

.strengths-head h3{
    font-weight: 600;
    padding-bottom: 30px;
    margin-bottom: 0px!important;
}

.strengths-head p{
    color: #666;
    line-height: 1.8;
}

.strengths-main{
    padding-top: 100px;
}

.card {
    display: inline-block;
    vertical-align: top;
    width: 200px;
    margin-right: 135px;
    position: relative;
    margin-bottom: 138px;
    border: 0px;
    background: #f7f7f7
}

.card-head {
    display: flex;
    flex-direction: row;
}

.card-head__text p{
    padding-left: 20px;
    margin-bottom: 0px!important;
    font-weight: 700;
    font-size: 14px;

}

.card-body{
    padding: 30px 0 0 0 ;
}

.card-body p{
   color: #666;
   line-height: 1.8;
}

.our-team{
    background: #1a1a1a;
    position: relative;
    width: 100%;
    height: auto;
}

.our-team__main{
    padding: 0px 80px;
    display: flex;
}

.img-contain{
    width: 180px;
    height: 250px;
    overflow: hidden;
}

.img-contain img{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 600px){
   
    .about-carousel{
        padding: 0px;
    }

    .about-carousel__inner{
        padding: 0px;
        height: 65vh;
    }

    .strengths-main{
        padding-top: 60px;
    }
    .strengths{
        padding: 60px 0px 0px;

        .strengths-head{
            padding-left: 20px;
            padding-right: 0px;
            width: 65%;

            h3{
                padding-bottom: 15px;
            }
        }

        .card{
            margin-right: 0px;
            margin-bottom: 60px;
            margin-left: 20px;
            width: 65%;

            .card-body{
                padding-top: 15px
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 767px){
    .about-carousel{
        padding: 0px;
    }

    .about-carousel__inner{
        padding: 0px;
        height: 65vh;
    }

    .strengths-main{
        padding-top: 60px;
    }
    .strengths{
        padding: 60px 0px 0px;

        .strengths-head{
            padding-left: 20px;
            padding-right: 0px;
            width: 65%;

            h3{
                padding-bottom: 15px;
            }
        }

        .card{
            margin-right: 0px;
            margin-bottom: 60px;
            margin-left: 20px;
            width: 65%;

            .card-body{
                padding-top: 15px
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .about-carousel{
        padding: 0px;
    }

    .strengths-main{
        padding-top: 60px;
    }

    .strengths{
        padding: 60px 0px 0px;

        .strengths-head{
            padding-left: 20px;
            padding-right: 0px;
            width: 65%;

            h3{
                padding-bottom: 15px;
            }
        }

        .card{
            margin-right: 0px;
            margin-bottom: 60px;
            margin-left: 20px;
           

            .card-body{
                padding-top: 15px
            }
        }
    }

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1360px){

}